<script>
import { ASSIGN_SHOP_TYPE } from '@/store/actions.type'
import { mapState } from 'vuex'
import OnboardingCard from '../../../registration/OnboardingCard.vue'
import OnboardingHeading from '../../../registration/OnboardingHeading.vue'
import Stepper from '../../../registration/Stepper.vue'

export default {
  name: 'SelectStoreType',

  components: {
    OnboardingCard,
    OnboardingHeading,
    Stepper
  },

  computed: {
    ...mapState('shop', ['newShop']),
    ...mapState('auth', ['user_id'])
  },

  methods: {
    setShopType(type) {
      this.$store.dispatch(`shop/${ASSIGN_SHOP_TYPE}`, { type })
      this.$router.push({ name: 'registration.woocommerce.verify-store-url' })
      window.sessionStorage.setItem('type', type)
    }
  }
}
</script>

<template>
  <section class="onboarding v2">
    <Stepper />
    <router-link to="/registration/woocommerce/continue-later" class="btn-later">
      Signout, continue later
    </router-link>

    <div class="p-t-5">
      <OnboardingHeading heading="Select store type" />
    </div>

    <div class="d-flex justify-center store-selector">
      <OnboardingCard small>
        <figure class="store-image">
          <img src="@/assets/images/source-store.png" alt="Source store">
        </figure>

        <h3 class="text-center">Source Store</h3>

        <p>This store is source of truth for all original inventory and product information.</p>
        <p>This account fulfills and ships orders from connected stores.</p>
        <p>Examples:</p>

        <ul class="p-l-2">
          <li>The primary online store</li>
          <li>Store connected to the source warehouses</li>
          <li>Supplies stock to other retailers</li>
          <li>A brand store</li>
        </ul>

        <v-btn
          @click="setShopType('source')"
          block
          class="onboarding-btn m-t-4"
          color="primary"
          elevation="0">
          Select store type
        </v-btn>
      </OnboardingCard>

      <OnboardingCard small>
        <figure class="store-image">
          <img src="@/assets/images/destination-store.png" alt="Destination store">
        </figure>

        <h3 class="text-center">Destination Store</h3>
        <p>This store syncs inventory and product information from a source store.</p>
        <p>This store creates orders containing synced products for other stores to fulfill.</p>
        <p>Examples:</p>

        <ul class="p-l-2">
          <li>Expansion store (different region)</li>
          <li>Third party retailer</li>
          <li>Dropshipping store</li>
          <li>Extra sales channel to the source</li>
        </ul>

        <v-btn
          @click="setShopType('destination')"
          block
          class="onboarding-btn m-t-4"
          color="primary"
          elevation="0">
          Select store type
        </v-btn>
      </OnboardingCard>
    </div>
  </section>
</template>
